<!----------------------------------------------------------------------------------------->
<!----------------------    The Search at top right   ------------------------>
<!----------------------------------------------------------------------------------------->

<span class="top-keyword-top-span">
    <form (submit)="onTopKeyWordSearchClick()" action="#">
        <input type="text"
                placeholder="Enter keyword"
                name="topKeyWordSearchText"
                [(ngModel)]="topKeyWordSearchText">
                <button [disabled]="loading" type="submit" class="btn-canano btn-canano-primary btn-canano-xs mr-1 topSearch"
                (click)="onTopKeyWordSearchClick()">
                Search
        </button>
    </form>
</span>
<div>
    <span *ngIf="!loading">
        &nbsp;
    </span>
    <span *ngIf="loading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{loadingMessage}}
    </span>
</div>
