<!------------------------------------------------------------------------->
<!--------------- Top header, parent of top right search  ----------------->
<!------------------------------------------------------------------------->

<div class="header-div">
    <table class="header-table" style="background-color: #ffffff">
        <tr>
            <td colspan="2">
                <span>
                    <a href="https://www.cancer.gov/" target="_blank" rel="nofollow noreferrer">
                        <img class="nih-logo-00" src="assets/images/CBIIT-36px-Logo-COLOR_contrast.png" alt="NIH Logo0">
                    </a>
                </span>
            </td>
        </tr>
        <tr>
            <td>
                <a [routerLink]="['/home']" routerLinkActive="router-link-active">
                <img src="assets/images/appLogo-nanolab.gif" alt="caNanoLab">
                </a>
            </td>
            <td>
                <span class="float-right nih-link-td">
                    <div class="link">
                        <a style="float: right" class="nih-link" href="https://www.nih.gov">U.S. National Institutes of Health</a>
                    </div>
                    <canano-top-keyword-search
                        style="float: right"
                        class="m-0 p-0"
                    ></canano-top-keyword-search>
                </span>
            </td>

        </tr>
        <!-- ___CGC_START_ANNOUNCE___
        <tr>
            <td colspan="3" class="announce">___CGC_ANNOUNCE_MESSAGE___</td>
        </tr>
        ___CGC_END_ANNOUNCE___ -->
    </table>
</div>
