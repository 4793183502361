// -------------------------------------------------------------------------
// --------------- Top header, parent of top right search  -----------------
// -------------------------------------------------------------------------

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'canano-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
