<!-- Button trigger modal -->
<a href="" *ngIf="!hide" data-toggle="modal" data-target="#disclaimer">Disclaimer</a>

  <!-- Modal -->
  <div class="modal fade" id="disclaimer" tabindex="-1" role="dialog" aria-labelledby="disclaimerTitle" aria-hidden="true">
        <div class="modal-dialog-lg modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="disclaimerLongTitle">caNanoLab Disclaimer</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <h5>
                LEGAL RULES OF THE ROAD
            </h5>
            <P>
                The cancer Nanotechnology Laboratory (caNanoLab) portal is provided as a public service by the National Cancer Institute (NCI) to foster the rapid dissemination of nanomaterial characterizations to the scientific community and the public. This data may already be published or otherwise in the public domain. In addition, confidential information may be posted which has not yet been published or is the subject of an application to be filed. All data may be subject to copyright provisions. By submitting nanomaterial characterizations to caNanoLab, you are certifying that you are authorized to release the data. You also certify that you will post only data generated and/or produced by you or your laboratory, and that you will consult with your institution's technology development office before posting or disclosing confidential information which may be patentable. You may browse, download, and use the data for non-commercial, scientific and educational purposes. However, you may encounter documents or portions of documents contributed by private institutions or organizations. Other parties may retain all rights to publish or produce these documents. Commercial use of the documents on this site may be protected under United States and foreign copyright laws. In addition, some of the data may be the subject of patent applications or issued patents, and you may need to seek a license for its commercial use.
            </P>
            <h5>
                PRIVACY ACT NOTIFICATION STATEMENT
            </h5>
            <P>
                The caNanoLab web portal requires users to register before submitting data to the repository. The primary use of the portal is to allow the National Cancer Institute to provide a repository as a public service to foster the rapid dissemination of nanomaterial characterizations to the scientific community and the public. Collection of this information is authorized under Title IV of the PHS Act, Section 410[285]. This information may be disclosed to researchers for research purposes, contractors responsible for the maintenance of the repository and to other registered repository users for non-commercial, scientific and educational purposes. Submission of this information is voluntary, however, in order for us to register you as a user of this repository, you should complete all fields. For additional information, please refer to the Legal Rules noted above, and the link to our Privacy Policy.
            </P>
            <h5>
                EXTERNAL SITE DISCLAIMER
            </h5>
            <P>
                Some NIH sites may provide links to other Internet sites only for the convenience of World Wide Web users. NIH is not responsible for the availability or content of these or offered at these other Internet sites.
            </P>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
