<!----------------------------------------------------------------------------------------->
<!----------------------              "Top Main Menu"              ------------------------>
<!----------------------     The Top menu just below the header    ------------------------>
<!----------------------------------------------------------------------------------------->
<nav class="navbar navbar-expand-lg nav-menu-top">
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
            <li class="nav-item">
                <button class="nav-link nav-menu-top-item disabled">RELATED LINKS</button>
            </li>
            <ng-container *ngFor="let item of topMenuItemNames; let i = index">
                <li class="nav-item">
                    <!--  Here check for visible & enabled  -->
                    <button [hidden]="currentRoute=='/home'&&item['name']=='HOME'"
                            [ngClass]="{
                                        'btn-canano-disabled':! enableMenuArray[i],
                                        'hide': ! visibleMenuArray[i]
                                        }"
                            class="nav-link nav-menu-top-item"
                            (click)="onMenuSelect(item)">
                        {{item['displayName']}}
                    </button>
                </li>
            </ng-container>
        </ul>
    </div>
</nav>
