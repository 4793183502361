<div class="modal-header">
    <h4 class="modal-title">Session Timeout Notification</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>For your protection you are about to be automatically logged out of caNanoLab. Your session will expire in
        {{time_remaining}} at {{expire_time}} if you remain inactive.</p>
    <p>If you would like to keep your session active, move your mouse or press the tab button on your keyboard.</p>
</div>
<div class="modal-footer">

</div>

